import React, { useState, useEffect } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  PiArrowFatLineLeftThin,
  PiArrowSquareOutLight,
  PiArrowFatLinesRightThin,
} from "react-icons/pi";
import { Helmet } from "react-helmet";
import { fetchProjects } from "../api/projects";

const Detail = () => {
  const { urlID } = useParams();
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);
  const [projects, setProjects] = useState(null); // Store projects data
  const [project, setProject] = useState(null);

  // Fetch projects only once and cache it in sessionStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        setImageLoading(true);
        const fetchedProjects = await fetchProjects();
        setProjects(fetchedProjects); // Cache projects in state
        sessionStorage.setItem("projects", JSON.stringify(fetchedProjects)); // Store in sessionStorage
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    // Check if projects are already cached in sessionStorage
    const cachedProjects = sessionStorage.getItem("projects");
    if (cachedProjects) {
      setProjects(JSON.parse(cachedProjects)); // Load from sessionStorage
    } else {
      fetchData();
    }
  }, []);

  // Select the project based on urlID when projects are loaded
  useEffect(() => {
    if (projects) {
      const selectedProject = projects.find((p) => p.urlID === urlID);
      setProject(selectedProject);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top after loading
    }
  }, [urlID, projects]);

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  // Find the next or previous project index for navigation
  const findProjectIndex = (direction) => {
    const projectIndex = projects.findIndex((p) => p.urlID === urlID);
    const maxIndex = projects.length - 1;

    if (direction === "next") {
      return projectIndex < maxIndex ? projectIndex + 1 : 0;
    }
    return projectIndex > 0 ? projectIndex - 1 : maxIndex;
  };

  // Handle navigation and scroll to top
  const handleNavigation = (direction) => {
    const nextIndex = findProjectIndex(direction);
    navigate(`/projekty/${projects[nextIndex].urlID}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!project) {
    return (
      <div className="w-6/12 m-auto py-12 lg:py-60 text-center">
        Načítám api data... nicméně se zdá, že je někde chyba <br />
        <NavLink to={"/"}>
          <button className="btn-primary">zpět na úvodní stránku</button>
        </NavLink>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Projekt ${project.name} - Michal Jirák - tvorba a redesign webů`}</title>
        <meta property="og:title" content={project.name || ""} />
        <meta
          property="og:description"
          content={project.longDescription || ""}
        />
        <meta name="description" content={project.longDescription || ""} />
      </Helmet>
      <section className="container m-auto py-12 lg:py-32" id="project-detail">
        <div className="w-9/12 lg:w-full m-auto flex flex-wrap flex-row items-start justify-between animate__animated animate__fadeIn animate__fast">
          <div className="w-fit order-3 lg:order-1 fixed md:relative top-1/4 left-2">
            <button
              className="btn-mobile-secondary md:btn-secondary px-2 md:px-4 mt-0"
              onClick={() => handleNavigation("prev")}
            >
              <PiArrowFatLineLeftThin />
              <span className="hidden md:block">Předchozí</span>
            </button>
          </div>
          <div className="w-full lg:w-4/12 order-1 lg:order-2">
            <div className="flex items-start flex-wrap gap-1 w-fit mb-6">
              {project.type &&
                project.type.map((type, index) => (
                  <div key={index} className={type}></div>
                ))}
            </div>

            <div className="">
              <p className="text-sm text-gray-400">
                {project.year ? project.year : <Skeleton width={100} />}
              </p>
              <h2 className="text-2xl font-bold">
                {project.name ? project.name : <Skeleton width={200} />}
              </h2>
              <p className="text-gray-600">
                {project.description ? (
                  project.description
                ) : (
                  <Skeleton count={2} />
                )}
              </p>
              <p className="my-4">
                {project.longDescription ? (
                  project.longDescription
                ) : (
                  <Skeleton count={3} />
                )}
              </p>
            </div>
            <div className="w-fit mt-2 lg:mb-auto">
              <a href={project.url} target="_blank" rel="noreferrer">
                <div className="flex items-start gap-1">
                  <p className="underline text-xs md:text-base truncate">
                    {project.url}
                  </p>
                  <PiArrowSquareOutLight className="-mt-2" />
                </div>
              </a>
            </div>
            <div className="mb-6 md:mb-0">
              <a href={project.url} target="_blank" rel="noreferrer">
                <button className="btn-primary">přejít na web</button>
              </a>
            </div>
          </div>
          <div className="w-full lg:w-4/12 flex flex-col items-center order-2 lg:order-3">
            {project.detailimageUrl ? (
              <>
                {/* Display the SVG loader while the image is loading */}
                {imageLoading && (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 animate-spin dark:text-gray-600 fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                )}

                <img
                  src={project.detailimageUrl}
                  alt={project.name}
                  className={`rounded-lg shadow-xl w-full h-auto transition-opacity duration-300`}
                  onLoad={handleImageLoad}
                />
              </>
            ) : (
              <Skeleton height={200} width="100%" />
            )}
          </div>
          <div className="w-fit order-4 mr-0 fixed md:relative top-1/4 md:top-0 right-2">
            <button
              className="btn-mobile-secondary md:btn-secondary px-2 md:px-4 md:p mt-0"
              onClick={() => handleNavigation("next")}
            >
              <span className="hidden md:block">Další</span>
              <PiArrowFatLinesRightThin />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Detail;
