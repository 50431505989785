import React, { useEffect } from "react";
import useSessionStorage from "../hooks/useSessionStorage"; // Adjust the path as necessary

const ContactForm = () => {
  const [formData, setFormData] = useSessionStorage("formData", {
    name: "",
    email: "",
    message: "",
    service: "",
  });

  // Effect to load selected service from sessionStorage
  useEffect(() => {
    const selectedService = sessionStorage.getItem("selectedService");
    if (selectedService) {
      setFormData((prevData) => ({
        ...prevData,
        service: selectedService, // Preselect the service if available
      }));
    }
  }, [setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data to the server
    fetch("https://app.headlessforms.cloud/api/v1/form-submission/08QPliphr2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert("Form submitted successfully!");
          // Clear the form data
          setFormData({ name: "", email: "", message: "", service: "" });
          // Clear selected service from sessionStorage if necessary
          sessionStorage.removeItem("selectedService");
        } else {
          alert("There was an error submitting the form.");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        alert("There was an error submitting the form.");
      });
  };

  return (
    <div className="w-full md:w-4/12 m-auto p-6 md:p-10 bg-white/40 shadow-xl rounded-lg">
      <form onSubmit={handleSubmit}>
        <div className="mb-8">
          <label htmlFor="name" className="block font-medium mb-1">
            Vaše jméno
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded border focus:outline-none focus:border-gray-800"
            placeholder="Jan Novák"
            required
          />
        </div>
        <div className="mb-8">
          <label htmlFor="email" className="block font-medium mb-1">
            Emailová adresa
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded border focus:outline-none focus:border-gray-800 relative"
            placeholder="email@seznam.cz"
            required
          />
        </div>

        <div className="mb-8">
          <label htmlFor="service" className="block font-medium mb-1">
            Typ služby
          </label>
          <select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded border focus:outline-none focus:border-gray-800"
          >
            <option value="wordpress">Wordpress</option>
            <option value="elementor">Elementor</option>
            <option value="html">HTML</option>
            <option value="else">něco jiného..</option>
          </select>
        </div>

        <div className="mb-8">
          <label htmlFor="message" className="block font-medium mb-1">
            Vaše zpráva či dotaz
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="w-full px-4 py-2 rounded border focus:outline-none focus:border-gray-800"
            placeholder="Mám zájem o vytvoření jednoduchého jednostránkového webu.."
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-10/12 m-auto py-2 bg-gray-800 text-white rounded hover:bg-gray-600 focus:outline-none animate-pulse"
        >
          Odeslat
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
