// react + dependencies
import { Navigate, Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";
import "animate.css";
import React, { createContext, useContext, useEffect, useState } from "react";
// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Detail from "./components/Detail";
// pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Services from "./pages/Services";

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-5BQMMKV",
};
TagManager.initialize(tagManagerArgs);

// Create a context for session storage
const SessionStorageContext = createContext();

export const useSessionStorage = () => {
  return useContext(SessionStorageContext);
};

const SessionStorageProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    return JSON.parse(sessionStorage.getItem("myData")) || null;
  });

  useEffect(() => {
    if (data) {
      sessionStorage.setItem("myData", JSON.stringify(data));
    }
  }, [data]);

  return (
    <SessionStorageContext.Provider value={[data, setData]}>
      {children}
    </SessionStorageContext.Provider>
  );
};

function App() {
  return (
    <SessionStorageProvider>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projekty" element={<Projects />} />
        <Route path="/projekty/:urlID" element={<Detail />} />
        <Route path="/sluzby" element={<Services />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </SessionStorageProvider>
  );
}

export default App;
