import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PiArrowDownLight } from "react-icons/pi";

const AccordionItem = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full bg-white/40 shadow-xl rounded-lg my-5">
      <div
        className="flex justify-between items-center py-4 cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="flex items-center px-4">
          <span
            className={`transition-transform duration-300 transform ${
              isOpen ? "rotate-180" : ""
            }`}
          >
            <PiArrowDownLight />
          </span>
          <h4 className="ml-4 text-lg font-medium text-start">{title}</h4>
        </div>
      </div>
      <div
        className={`bg-white/50 rounded-b-lg overflow-hidden transition-max-height duration-300 ease-in-out ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <p className="border-t border-black py-2 px-4 text-left leading-8">
          {description}
        </p>
      </div>
    </div>
  );
};

const Services = () => {
  const services = [
    {
      title: "Tvorba webů",
      description:
        "Rád vám pomohu s vytvořením nové webové prezentace. Ať už se jedná o jednoduché portfolio, nebo o složitější vícestránkový web. Potřebuju ke své prezentaci připravit objednávkový e-shop? Není problém.",
    },
    {
      title: "Redesign webů",
      description:
        "Vlastníte už svůj web, ale dlouho jste na něm neprovedli žádné úpravy, případně ho chcete dostat do nového kabátku? Na webu je potřeba čas od času udělat nějakou tu práci, ať už jde o optimalizaci výkonu nebo aktualizaci důležitých prvků.",
    },
    {
      title: "SEO optimalizace",
      description:
        "Pomohu vám se SEO optimalizací tak, aby se váš web nabízel vyhledávacím enginům jak na Google, tak třeba i na Seznamu. Je to důležitá práce s klíčovými slovy a nastavením obsahu tak, aby co nejvíce odpovídal tomu, co uživatelé na internetu hledají.",
    },
    {
      title: "Analytika a napojení na Google služby",
      description:
        "V souvislosti s vyhodnocováním uživatelského chování na vašem webu, je potřeba mít správně nastavené analytické služby. Tuto potřebu nejlépe pokrývají standardní Google Analytics, s jejichž nastavením vám rád pomohu. Následně web napojím na Google Search Console, aby byla zajištěná jeho indexovatelnost.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Služby - Michal Jirák - tvorba a redesign webů</title>
        <meta
          property="og:title"
          content="Služby - Michal Jirák - tvorba a redesign webů"
        />
        <meta
          property="og:description"
          content="Na této stránce jsou k dispozici služby, se kterými vám dokážu poradit."
        />
        <meta
          name="description"
          content="Na této stránce jsou k dispozici služby, se kterými vám dokážu poradit."
        />
      </Helmet>
      <section className="w-full pb-16 md:pb-32" id="projects">
        <div className="container mx-auto">
          <div className="text-center w-full">
            <div className="h-[20vh] flex flex-col items-center justify-center">
            <h3 className="uppercase text-4xl md:text-5xl font-bold mb-2 tracking-widest relative z-20">
                nabízené služby
                <span className="absolute z-10 -top-3 -right-3 text-gray-500/20">nabízené služby</span>
              </h3>
            </div>
            <div className="w-full lg:w-6/12 m-auto pt-6 md:pt-0 ">
              <div className="w-full animate__animated animate__fadeIn animate__fast">
                {services.map((service, index) => (
                  <AccordionItem
                    key={index}
                    title={service.title}
                    description={service.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
