export const fetchProjects = () => {
  return [
    {
      id: 12,
      name: "Petr Borkovec",
      description: "realitní makléř",
      longDescription: 
        "Petrovy webovky vznikly na základě potřeby vlastního makléřského portfolia, včetně představení, svého prostoru pro pulibkování článků a zobrazování referencí na jeho práci. Kontrolu nad zobrazovaným obsahem chtěl mít ve svých rukou, avšak bez možných zásahů do konstrukce webu. Na základě rozhodnutí, která jsme nemohli ovlivnit, jsme museli web po krátké době vyřadit z provozu.",
      imageUrl: "../img/projects/petrborkovec.webp",
      detailimageUrl: "../img/projects/petrborkovec-full.webp",
      year: "2024",
      url: "",
      urlID: "petrborkovecmakler",
      type: ["wordpress", "elementor"],
    },
    {
      id: 11,
      name: "Kavalier Photographer",
      description: "fotografka",
      longDescription: 
        "Áďa krásně fotí jak svatby, tak nejrůznější párové, těhotenské nebo dětské fotografie. Práce pro kamarády často nebývají to pravé ořechové, to ale nebyl tento případ :-) Web je postavený na platformě React s Node.js a Tailwind CSS pro dostylování.",
      imageUrl: "../img/projects/kavalier-photographer.webp",
      detailimageUrl: "../img/projects/kavalier-photographer-full.webp",
      year: "2024",
      url: "https://kavalierphotographer.cz/",
      urlID: "kavalierphotographer",
      type: ["react", "tailwind", "css"],
    },
    {
      id: 10,
      name: "Kovovýroba Rohlík",
      description: "zakázková kovovýroba",
      longDescription:
        "Web zaměřující se na prezentaci a následný prodej standardních, ale i unikátních kovovýrobků, za použití Wordpress backendu, z důvodu vlastní správy obsahu a produktové struktury.",
      imageUrl: "../img/projects/kovorohlik.webp",
      detailimageUrl: "../img/projects/kovorohlik-full.webp",
      year: "2024",
      url: "https://www.kovorohlik.cz/",
      urlID: "kovorohlik",
      type: ["wordpress", "elementor", "woocommerce", "css"],
    },
    {
      id: 9,
      name: "PPE Zilla",
      description: "servisní partner Sundström",
      longDescription:
        "PPE Zilla byla moje první praktická implementace React řešení, kdy spolu s frameworkem Tailwind CSS a Node JS vznikl multijazyčný web, který nabízí služby všem, kteří mají zájem o audit či informace o produktech určených k ochraně dýchacích cest od společnosti Sundström.",
      imageUrl: "../img/projects/ppezilla.webp",
      detailimageUrl: "../img/projects/ppezilla-full.webp",
      year: "2023",
      url: "https://ppezilla.com/",
      urlID: "ppezilla",
      type: ["react", "tailwind"],
    },
    {
      id: 8,
      name: "Běhám a sním",
      description: "na svém běžeckém obláčku",
      longDescription:
        "Toto byla srdeční záležitost pro mou manželku, která svou pozitivou a běžeckým nadšením motivuje celé své okolí. Cílem bylo podělit se o co nejvíce informací se svými známými, takže vznikla jednoduchá struktura v HTML formátu s vlastním CSS a trochou JS. Nové články se píší přímo ve zdrojovém kódu, takže správa není úplně intuitivní, za to je ale vše přesně tak, jak chceme a nejsme vázání šablonovým řešením.",
      imageUrl: "../img/projects/behamasnim.webp",
      detailimageUrl: "../img/projects/behamasnim-full.webp",
      year: "2023",
      url: "https://behamasnim.cz/",
      urlID: "behamasnim",
      type: ["html", "css", "bootstrap"],
    },
    {
      id: 7,
      name: "Snadné seznámení",
      description: "seznamovací večery pro nezadané",
      longDescription:
        "Web na Wordpress a Elementor, s mnoha prvky vlastního CSS. Záměrem byl prezentační web, na kterém se zájemci o tzv. Speed Dating doví potřebné informace a následně se zaregistrují na jeden z vypsaných termínů. Klient si termíny spravuje přímo na nástěnce Wordpress a odpovědi zájemců dostává se všemi potřebnými informacemi přímo na svůj email.",
      imageUrl: "../img/projects/snadneseznameni.webp",
      detailimageUrl: "../img/projects/snadneseznameni-full.webp",
      year: "2023",
      url: "https://snadneseznameni.cz/",
      urlID: "snadneseznameni",
      type: ["wordpress", "elementor", "css"],
    },
    {
      id: 6,
      name: "Laufr interiéry",
      description: "interiér, který budete milovat",
      longDescription:
        "Další důkaz jednoduchého prezentačního webu na Wordpress, umožňující jednoduchou správu obsahu. Prezentační web s galerií hotových prací včetně kontaktního formuláře, kterým lze poptat služby na míru vyráběného interiéru.",
      imageUrl: "../img/projects/laufr.webp",
      detailimageUrl: "../img/projects/laufr-full.webp",
      year: "2023",
      url: "https://laufr.cz/",
      urlID: "laufr",
      type: ["wordpress", "elementor"],
    },
    {
      id: 5,
      name: "Torba s.r.o.",
      description: "zahradnictví Libochovice",
      longDescription:
        "Torba vznikla jako re-design původního webu tohoto rodinného zahradnictví. Původní web jsem přenesl na Wordpress, umožňující jednoduchou správu obsahu. Důraz zde byl kladen na SEO a přehlednost celého webu tak, aby se potenciální zákazníci neměli problém do něj navigovat. Původním záměrem byla i produktová sekce včetně obchodního procesu, který se nakonec nerealizoval z kapacitních důvodů.",
      imageUrl: "../img/projects/torba.webp",
      detailimageUrl: "../img/projects/torba-full.webp",
      year: "2022",
      url: "https://torba.cz/",
      urlID: "torba",
      type: ["wordpress", "elementor", "css"],
    },
    {
      id: 4,
      name: "Ubytování Havlík",
      description: "pronájem chalupy v Sobotce",
      longDescription:
        "Projekt o pronájmu ubytování této stylové chalupy byla příjemná zkušenost s tvorbou jednostránkového webu. Opět na platformě Wordpress s Elementorem, umožňující jednoduchou a rychlou správu obsahu, konkrétně obrázkové galerie a cen za jednotlivé termíny. Je možné napojení na externí rezervační systémy, které jsme nakonec nepoužili.",
      imageUrl: "../img/projects/havlik.webp",
      detailimageUrl: "../img/projects/havlik-full.webp",
      year: "2022",
      url: "https://ubytovani-havlik.cz/",
      urlID: "ubytovanihavlik",
      type: ["wordpress", "elementor"],
    },
    {
      id: 3,
      name: "Tereza Zichová",
      description: "malba, kresba, grafika",
      longDescription:
        "Terka je nadaná umělkyně, se kterou je radost spolupracovat. Vytvořil jsem pro ní web na Wordpress s Elementorem, který umožňuje vlastní správu celého grafického obsahu. Podle jednotlivých sekcí lze přímo v galerii jednoduše na pár kliknutí změnit loga, obrázky, či případně barvy. Důležité zde byla optimalizace všech obrazů ve vysokém rozlišení tak, aby se daly jednoduše prohlížet i na mobilních zařízeních.",
      imageUrl: "../img/projects/terezazichova.webp",
      detailimageUrl: "../img/projects/terezazichova-full.webp",
      year: "2022",
      url: "https://terezazichova.cz/",
      urlID: "terezazichova",
      type: ["wordpress", "elementor"],
    },
    {
      id: 2,
      name: "Jiráci na cestách",
      description: "cestovatelský blog",
      longDescription:
        "S manželkou jsme vášniví turisté a po několika letech cestování a navštěvování zajímavých míst nám nedalo se o naše zkušenosti nepodělit. A tak vznikl můj v pořadí druhý projekt - Jiráci na cestách. Web postavený na Wordpressu a Elementoru, vše budované na prázdné šabloně. Web klade velký důraz na SEO (optimalizaci pro vyhledávače), abychom přibližovali informace co nejširšímu okruhu podobných cestovatelů, jako jsme my dva.",
      imageUrl: "../img/projects/jiracinacestach.webp",
      detailimageUrl: "../img/projects/jiracinacestach-full.webp",
      year: "2021",
      url: "https://jiracinacestach.cz/",
      urlID: "jiracinacestach",
      type: ["wordpress", "elementor", "css"],
    },
    {
      id: 1,
      name: "Petr Polák",
      description: "životní spokojenost",
      longDescription:
        "Petrův web byl pro mě moje první větší zkušenost s webdesignem. Cílem byl prezentační web s blog sekcí, kterou si bude moct jednoduše spravovat i sám. Na prázdné Wordpress šabloně jsem s pomocí Elementoru vytvořil celý web, s funkční a filtrovatelnou blog sekcí. Zároveň jsme na myšlence záchranného kruhu vytvořili celkovou identitu webu, včetně log a ostatních grafických materiálů.",
      imageUrl: "../img/projects/zivotnispokojenost.webp",
      detailimageUrl: "../img/projects/zivotnispokojenost-full.webp",
      year: "2021",
      url: "https://zivotnispokojenost.cz/",
      urlID: "zivotnispokojenost",
      type: ["wordpress", "elementor"],
    },
  ];
};
