import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  PiEnvelopeSimpleOpenLight,
  PiLinkedinLogo,
  PiTelegramLogo,
} from "react-icons/pi";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileMenuOpen]);

  return (
    <>
      <header className="w-full">
        <nav className="container m-auto py-10 flex flex-row justify-between items-center capitalize text-gray-500 ">
          <div className="text-start relative z-50">
            <a href="/">
              <div
                className="flex flex-row items-end md:items-center gap-3 md:hover:underline-offset-8 hover:underline"
                title="Zpět na úvod s refreshem stránky"
              >
                <img
                  src="../img/profile/profilovkaPNG.webp"
                  className="w-6 md:w-12 rounded-full rotate-12 md:hover:scale-110 md:transition-all"
                  alt="Michal Jirák"
                />
                <h1 className="text-black text-lg">michal jirák</h1>
              </div>
            </a>
          </div>
          <nav className="hidden md:flex flex-row gap-6 text-black text-lg">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              úvod
            </NavLink>
            <NavLink
              to="/projekty"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              projekty
            </NavLink>
            <NavLink
              to="/sluzby"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              služby
            </NavLink>
            <NavLink
              to="/kontakt"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              kontakt
            </NavLink>
          </nav>
          <div className="md:hidden flex items-center order-3 md:order-2">
            <button
              onClick={toggleMobileMenu}
              className="focus:outline-none outline-none border-none text-black bg-transparent z-50"
              aria-label="Mobile Menu Toggle"
            >
              <div className={`menu-icon ${mobileMenuOpen ? "open" : ""}`}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </button>
          </div>
          <div className="hidden md:flex flex-row items-center order-2 md:order-3 gap-4 text-lg text-black leading-none">
            <a
              href="mailto:jirak5@seznam.cz?subject=Webová poptávka"
              rel="noreferrer"
              target="_blank"
            >
              <div className="socials" title="Pošlete mi email">
                <PiEnvelopeSimpleOpenLight className="w-6 h-6" />
              </div>
            </a>
            <a href="https://t.me/michaljirak" rel="noreferrer" target="_blank">
              <div className="socials" title="Napište mi přes Telegram">
                <PiTelegramLogo className="w-6 h-6" />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/michal-jir%C3%A1k-572b0a162/"
              rel="noreferrer"
              target="_blank"
            >
              <div className="socials" title="Můj LinkedIn">
                <PiLinkedinLogo className="w-6 h-6" />
              </div>
            </a>
          </div>
        </nav>

        {/* Mobile Menu */}
        <div
          className={`fixed top-0 right-0 w-full h-full bg-slate-200 transform transition-transform duration-300 ${
            mobileMenuOpen ? "translate-x-0" : "translate-x-full"
          } z-40 overflow-hidden`}
        >
          <div className="h-full flex flex-col items-center justify-center gap-32 bg-gradient-to-r from-sky-100/30 from-10% to-sky-900/30 to-90%">
            <div className="flex flex-col items-center gap-10 capitalize text-xl">
              <NavLink
                to="/"
                end
                onClick={() => {
                  scrollToTop();
                  toggleMobileMenu();
                }}
              >
                úvod
              </NavLink>
              <NavLink
                to="/projekty"
                onClick={() => {
                  scrollToTop();
                  toggleMobileMenu();
                }}
              >
                projekty
              </NavLink>
              <NavLink
                to="/sluzby"
                onClick={() => {
                  scrollToTop();
                  toggleMobileMenu();
                }}
              >
                služby
              </NavLink>
              <NavLink
                to="/kontakt"
                onClick={() => {
                  scrollToTop();
                  toggleMobileMenu();
                }}
              >
                kontakt
              </NavLink>
            </div>

            {/* Mobile Socials */}
            <div className="flex flex-row gap-10">
              <a
                href="mailto:jirak5@seznam.cz?subject=Webová poptávka"
                rel="noreferrer"
                target="_blank"
              >
                <div className="socials">
                  <PiEnvelopeSimpleOpenLight className="w-8 h-8" />
                </div>
              </a>
              <a
                href="https://t.me/michaljirak"
                rel="noreferrer"
                target="_blank"
              >
                <div className="socials">
                  <PiTelegramLogo className="w-8 h-8" />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/in/michal-jir%C3%A1k-572b0a162/"
                rel="noreferrer"
                target="_blank"
              >
                <div className="socials">
                  <PiLinkedinLogo className="w-8 h-8" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
