// React + dependencies
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import ContactForm from "../components/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [preSelectedService, setPreSelectedService] = useState("");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageVisit",
        pageName: "kontakt",
      },
    });

    // Retrieve pre-selected service from session storage
    const storedData = sessionStorage.getItem("selectedService");
    if (storedData) {
      setPreSelectedService(storedData);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Kontakt - Michal Jirák - tvorba a redesign webů</title>
        <meta
          property="og:title"
          content="Kontakt - Michal Jirák - tvorba a redesign webů"
        />
        <meta
          property="og:description"
          content="Stránka se všemi kontaktními údaji, přehledně na jednom místě. Chcete-li si nechat zpracovat nezávaznou nabídku nebo máte jen dotaz, nebojte se mi napsat."
        />
        <meta
          name="description"
          content="Stránka se všemi kontaktními údaji, přehledně na jednom místě. Chcete-li si nechat zpracovat nezávaznou nabídku nebo máte jen dotaz, nebojte se mi napsat"
        />
      </Helmet>
      <section className="w-full pb-16 md:pb-32" id="kontakt">
        <div className="container mx-auto ">
          <div className="text-center">
            <div className="h-[20vh] flex flex-col items-center justify-center">
            <h3 className="uppercase text-4xl md:text-5xl font-bold mb-2 tracking-widest relative z-20">
                kontakt
                <span className="absolute z-10 -top-3 -right-3 text-gray-500/20">kontakt</span>
              </h3>
            </div>
            <div className="w-full animate__animated animate__fadeIn animate__fast">
              {/* Pass preSelectedService to the ContactForm */}
              <ContactForm preSelectedService={preSelectedService} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
