// react + dependencies
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    // Trigger event when the component mounts
    TagManager.dataLayer({
      dataLayer: {
        event: "pageVisit",
        pageName: "homepage",
      },
    });
  }, []);

  const handleServiceSelect = (service) => {
    sessionStorage.setItem("selectedService", service);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>Michal Jirák - tvorba a redesign webů</title>
        <meta
          name="description"
          content="Pro své klienty zajišťuji tvorbu moderních webových stránek od A po Z. Potřebujete nové webové stránky nebo redesign těch stávajících?"
        />
        <meta
          property="og:title"
          content="Michal Jirák - tvorba a redesign webů"
        />
        <meta
          property="og:description"
          content="Pro své klienty zajišťuji tvorbu moderních webových stránek od A po Z. Potřebujete nové webové stránky nebo redesign těch stávajících?"
        />
      </Helmet>
      <section className="w-full" id="home">
        <div className="container mx-auto">
          <div className="h-[40vh] flex flex-col sm:flex-row justify-between items-center gap-10 mb-12 lg:mb-0">
            <div className="w-10/12 lg:w-full sm:w-6/12 m-auto tracking-widest text-center ">
              <div className="text-center">
                <h1 className="uppercase text-5xl font-bold mb-2 tracking-widest relative">
                  michal jirák
                </h1>
                <h2 className="lowercase text-md font-light">
                  Tvorba a redesign webových stránek
                </h2>
              </div>
              <div className="mt-4 animate-pulse">
                <NavLink to="/projekty" onClick={scrollToTop}>
                  <button className="btn-primary">moje projekty</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full animate__animated animate__fadeIn animate__fast"
          id="wrap"
        >
          {/* Wordpress */}
          <div
            className="w-full border-t-none md:border-t border-sky-900/20"
            id="wordpress"
          >
            <div className="container m-auto flex flex-col sm:flex-row flex-wrap items-center ">
              <div className="w-full sm:w-6/12 flex order-2 sm:order-1">
                <div className="md:w-8/12 xl:w-6/12 mr-0 md:mr-20 m-auto my-0 md:my-32 p-4 md:p-0 ">
                  <div className="w-full">
                    <div className="text-start">
                      <h4 className="text-xl uppercase font-bold">wordpress</h4>
                      <p className="mt-6">
                        Wordpress považuji za základ vlastní administrace
                        webovek. Kdo se setkal s administrací vlastních webových
                        stránek, pravděpodobně to bylo právě na Wordpressu.
                        Vytváření stránek je poměrně jednoduché a rychlé, vše se
                        často odvíjí od výběru té správné šablony.
                      </p>
                      <NavLink
                        to="/kontakt"
                        onClick={() => {
                          scrollToTop();
                          handleServiceSelect("wordpress");
                        }}
                      >
                        <button className="btn-primary">chci wordpress</button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-6/12 flex sm:order-2 order-1">
                <div className="relative my-6 md:my-0">
                  <span className="absolute right-0 border border-white wordpress"></span>
                  <img
                    src="./img/tech/wp.webp"
                    className="shadow-lg rounded-none sm:rounded-lg"
                    alt="Wordpress dashboard"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Elementor */}
          <div
            className="w-full border-t-none md:border-t border-sky-900/20"
            id="elementor"
          >
            <div className="container m-auto flex flex-col sm:flex-row flex-wrap items-center ">
              <div className="w-full sm:w-6/12">
                <div className="relative my-6 md:my-0">
                  <span className="absolute left-0 border border-white  elementor"></span>
                  <img
                    src="./img/tech/elementor.webp"
                    loading="lazy"
                    className="shadow-lg rounded-none sm:rounded-lg"
                    alt="Elementor"
                  />
                </div>
              </div>
              <div className="w-full sm:w-6/12">
                <div className="md:w-8/12 xl:w-6/12 ml-0 md:ml-20 m-auto my-0 md:my-32 p-4 md:p-0 ">
                  <div className="w-full">
                    <div className="text-start">
                      <h4 className="text-xl uppercase font-bold">elementor</h4>
                      <p className="mt-6">
                        Elementor je jeden z nejpoužívanějších page builderů pro
                        Wordpress. Ve zkratce se jedná o drag&drop systém, kde
                        si vybíráte z předdefinovaných komponent, které
                        umísťujete na stránky dle libosti. Tvorba stránek je
                        poměrně intuitivní, uživatelsky umožňuje správu
                        vlastních příspěvků, umísťování obrázků a podobně.
                        Doporučuji navrch používat jeho placenou verzi, která
                        umožňuje využívání například různých proměnných, které
                        zákazníkovi dokáží usnadnit práci mnoha způsoby.
                      </p>
                      <NavLink
                        to="/kontakt"
                        onClick={() => {
                          scrollToTop();
                          handleServiceSelect("elementor");
                        }}
                      >
                        <button className="btn-primary">chci elementor</button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* HTML */}
          <div
            className="w-full border-t-none md:border-t border-sky-900/20"
            id="html"
          >
            <div className="container m-auto flex flex-col sm:flex-row flex-wrap items-center ">
              <div className="w-full sm:w-6/12 flex order-2 sm:order-1">
                <div className="md:w-8/12 xl:w-6/12 mr-0 md:mr-20 m-auto my-0 md:my-32 p-4 md:p-0 ">
                  <div className="w-full">
                    <div className="text-start">
                      <h4 className="text-xl uppercase font-bold">html</h4>
                      <p className="mt-6">
                        Nakódované webovky bez vlastního CMS systému. K přípravě
                        takových stránek využívám webových standardů v podobě
                        HTML, CSS a JavaScriptu. Experimentuji s moderními
                        technologiemi jako jsou React, Tailwind CSS nebo Axios.
                        Výhodou může být údržba z finanční stránky, jelikož se
                        většinou dají hostovat zdarma,{" "}
                        <strong>zákazník tedy platí pouze cenu domény.</strong>
                      </p>
                      <NavLink
                        to="/kontakt"
                        onClick={() => {
                          scrollToTop();
                          handleServiceSelect("html");
                        }}
                      >
                        <button className="btn-primary">
                          chci vlastní html
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-6/12 flex order-1 sm:order-2">
                <div className="relative my-6 md:my-0">
                  <span className="absolute right-0 border border-white html"></span>
                  <img
                    src="./img/tech/html.webp"
                    className="shadow-lg rounded-none sm:rounded-lg"
                    loading="lazy"
                    alt="HTML"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
